<div class="container">
  <h5>Select a color palette</h5>
  <hr />
  <!-- <ul style="padding: 0;">
        <li style="list-style:none;cursor: pointer;">
            <div *ngFor="let item of colorPalette; let i = index" style="display: flex; justify-content: center;" (click)="onSelect(item)">
                <div style="color: #fff;">{{ i + 1 }}</div>
                <div *ngFor="let o of item.options" [ngStyle]="{'background-color': o.colorCode}" style="width: 100px; height: 50px; margin-bottom: 16px"></div>
            </div>
        </li>
    </ul> -->
  <div *ngFor="let group of groupedColorPalettes">
    <span
      style="
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0%;
        vertical-align: middle;
      "
      >{{ group.name }}</span
    >
    <ul style="padding: 0;">
      <li style="list-style: none; cursor: pointer; ">
        <ng-container *ngFor="let item of group.items">
          <div
            *ngIf="group.ids.includes(item.id)"
            style="
              display: flex;
              justify-content: space-evenly;
              width: 100%;
              height: 68;
              gap: 8px;
              border-radius: 12px;
              border: 1px solid #eee;
              padding: 16px;
              margin-bottom: 8px;
            "
            (click)="onSelect(item)"
          >
            <!-- <div style="color: #fff; padding: 5px">{{ item.id }}</div> -->
            <div
              *ngFor="let o of item.options"
              [ngStyle]="{ 'background-color': o.colorCode }"
              style="width: 35px; height: 36px; border-radius: 100%"
            ></div>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
