import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorPalette, IDWiseColorPalette } from 'src/app/data';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.css']
})
export class ColorPaletteComponent implements OnInit {

  colorPalette = ColorPalette;
  iDWiseColorPalette = IDWiseColorPalette

  constructor(
    public dialogRef: MatDialogRef<ColorPaletteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog
  ) { }
  groupedColorPalettes: any[] = [];
  getGroupedColorPalettes() {
    return this.iDWiseColorPalette.map(group => ({
      ...group,
      items: this.colorPalette
        .filter(c => group.ids.includes(c.id)) // Filter by ID
        .sort((a, b) => group.ids.indexOf(a.id) - group.ids.indexOf(b.id)) // Sort by ID order
    }));
  }
  ngOnInit(): void {
    this.groupedColorPalettes = this.getGroupedColorPalettes();
  }

  onSelect(item){
    console.log("item", item)
    this.closeModel(item.options);
  }

  closeModel(item) {
    this.dialogRef.close(item);
  }

}
